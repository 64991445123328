import Glide from '@glidejs/glide';
// import * as basicLightbox from "basiclightbox";
// import Glider from 'glider-js/glider';
import { Form } from '@netivo/base-scripts';
import GLightbox from 'glightbox';
import MenuTabs from "./menuTabs.js";

let lightbox = new GLightbox();

function toggleMenu(){
    let menu = document.querySelector('#mobile-menu');
    let mask = document.querySelector('#mask');
    let wrapper = document.querySelector('#wrapper');
    let content = document.querySelector('#content');
    document.querySelector('.js-hamburger').classList.toggle("active");
    // document.querySelector('#mask').toggle();
    menu.classList.toggle("toggled");
    mask.classList.toggle('shown');
    content.classList.toggle("toggled");
    if(menu.classList.contains("toggled")) {
        // wrapper.css('height', menu.height() + 'px');
        document.body.style.overflow = 'auto';
        document.querySelector('html').style.overflow = 'auto';
    }
    else {
        // wrapper.css('height', 'auto');
        document.body.style.overflow = 'auto';
        document.querySelector('html').style.overflow = 'auto';
    }
}

// window.initMap = initMap;

let mask = document.querySelector('#mask');
if (mask !== null) {
    let menu = document.querySelector('#mobile-menu');
    mask.addEventListener('click', (e) => {
        mask.classList.toggle('shown');
        menu.classList.toggle('toggled');
        if(!menu.classList.contains("toggled")) {
            document.body.style.overflow = 'auto';
            document.querySelector('html').style.overflow = 'auto';
        }
    });
}

let hamburger = document.querySelector('.js-hamburger');
if(hamburger !== null){
    hamburger.addEventListener('click', event => {
        event.preventDefault();
        toggleMenu();
    });
}

let mobile_close = document.querySelector('.js-mobile-close');
if (mobile_close != null) {
    mobile_close.addEventListener('click', function(e) {
        let menu = document.querySelector('#mobile-menu');
        if (menu != null && menu.classList.contains('toggled')) {
            let mask = document.querySelector('#mask');

            e.preventDefault();
            document.body.style.overflow = 'initial';
            document.querySelector('html').style.overflow = 'initial';
            menu.classList.remove('toggled');
            mask.classList.remove('shown');
            document.querySelector('.js-hamburger').classList.toggle("active");
        }
    });
}


let header = document.querySelector('.js-header');
let sticky = header.offsetTop;

window.onscroll = function(){stickyStart()};

function stickyStart() {
    if(window.pageYOffset > sticky){
        header.classList.add('header--scrolled');
    } else {
        header.classList.remove('header--scrolled');
    }
}

window.addEventListener('DOMContentLoaded', () => {

    let menu_carousels = document.querySelectorAll('.js-menu-carousel');
    if (menu_carousels !== null) {
        menu_carousels.forEach(carousel => {
            if(carousel !== null) {
                if(carousel.classList.contains('slider--shown'))
                {
                    let m_crls = null;
                    let prev_button = carousel.querySelector('[data-element="button-prev"]');
                    let next_button = carousel.querySelector('[data-element="button-next"]');
                    m_crls = new Glide(carousel, {
                        perView: 1,
                        gap: '0',
                        type: 'carousel',
                    }).mount();

                    prev_button.addEventListener('click', (e) => {
                        e.preventDefault();

                        m_crls.go('<');
                    });
                    next_button.addEventListener('click', (e) => {
                        e.preventDefault();

                        m_crls.go('>');
                    });
                    carousel.setAttribute('data-run', 1);
                }
            }
        })
    }


    let e_crls = null;
    let events_carousel = document.querySelector('.js-events-carousel');
    if (events_carousel !== null) {
        let prev_button = events_carousel.parentNode.querySelector('[data-element="button-prev"]');
        let next_button = events_carousel.parentNode.querySelector('[data-element="button-next"]');

        e_crls = new Glide(events_carousel, {
            perView: 1,
            gap: '0',
            type: 'carousel',
            bound: true,
            breakpoints: {
                991: {
                    perView: 1,
                },
            }
        }).mount();

        prev_button.addEventListener('click', (e) => {
            e.preventDefault();

            e_crls.go('<');
        });
        next_button.addEventListener('click', (e) => {
            e.preventDefault();

            e_crls.go('>');
        });
    }

    // let m_crls = null;
    // let menu_carousel = document.querySelector('.js-menu-carousel');
    // if (menu_carousel !== null) {
    //     let prev_button = menu_carousel.parentNode.querySelector('[data-element="button-prev"]');
    //     let next_button = menu_carousel.parentNode.querySelector('[data-element="button-next"]');
    //
    //     m_crls = new Glide(menu_carousel, {
    //         perView: 1,
    //         gap: '0',
    //         type: 'carousel',
    //         bound: true,
    //         breakpoints: {
    //             991: {
    //                 perView: 1,
    //             },
    //         }
    //     }).mount();
    //
    //     prev_button.addEventListener('click', (e) => {
    //         e.preventDefault();
    //
    //         m_crls.go('<');
    //     });
    //     next_button.addEventListener('click', (e) => {
    //         e.preventDefault();
    //
    //         m_crls.go('>');
    //     });
    // }





    let contact_form = document.querySelector('.js-contact-form');
    if (contact_form !== null) {
        new Form(contact_form, {
            action: '/wp-json/netivo/v1/contact',
            data: el => {
                return new Promise((resolve, reject) => {
                    let data = {
                        restaurant: '',
                        first_name: '',
                        email: '',
                        message: '',
                        action: 'nt_send_contact_form'
                    };

                    let form_first_name = el.querySelector('[name="first_name"]'),
                        form_email = el.querySelector('[name="addres_email"]'),
                        form_restaurant = el.querySelector('[name="restaurant"]'),
                        form_message = el.querySelector('[name="message"]');

                    if (form_first_name !== null) data.first_name = form_first_name.value;
                    if (form_restaurant !== null) data.restaurant = form_restaurant.value;
                    if (form_email !== null) data.email = form_email.value;
                    if (form_message !== null) data.message = form_message.value;

                    resolve( data );
                });
            },
            success: (el, response) => {
                if (response.status === 'success') {
                    let status = document.querySelector('[data-element="status"]');
                    let message = status.querySelector('[data-element="success-message"]');
                    status.querySelector('[data-element="error-message"]').style.display = 'none';
                    status.classList.add('shown');
                    setTimeout( () => {
                        message.classList.add('shown');
                        el.querySelector('[name="first_name"]').value = '';
                        el.querySelector('[name="addres_email"]').value = '';
                        el.querySelector('[name="message"]').value = '';
                        el.querySelector('[name="restaurant"]').value = '';
                    }, 500);

                    let close = message.querySelector('[data-element="close-button"]');
                    let close_top = message.querySelector('[data-element="close-button-top"]');
                    if (close !== null && close_top !== null) {
                        close.addEventListener('click', (e) => {
                            e.preventDefault();
                            message.classList.remove('shown');
                            setTimeout( () => {
                                status.classList.remove('shown');
                            }, 500);
                        });
                        close_top.addEventListener('click', (e) => {
                            e.preventDefault();
                            message.classList.remove('shown');
                            setTimeout( () => {
                                status.classList.remove('shown');
                            }, 500);
                        });
                    }
                }
            },
            error: (el, response) => {
                let status = document.querySelector('[data-element="status"]');
                let message = status.querySelector('[data-element="error-message"]');
                status.querySelector('[data-element="success-message"]').style.display = 'none';
                status.classList.add('shown')
                setTimeout( () => {
                    message.classList.add('shown');
                }, 500);

                let close = message.querySelector('[data-element="close-button"]');
                let close_top = message.querySelector('[data-element="close-button-top"]');
                if (close !== null && close_top !== null) {
                    close.addEventListener('click', (e) => {
                        e.preventDefault();

                        message.classList.remove('shown');
                        setTimeout( () => {
                            status.classList.remove('shown');
                        }, 500);
                    });
                    close_top.addEventListener('click', (e) => {
                        e.preventDefault();

                        message.classList.remove('shown');
                        setTimeout( () => {
                            status.classList.remove('shown');
                        }, 500);
                    });
                }
            }
        });
    }

    setTimeout(() => {
        let interactive_elements = document.querySelectorAll('.interactive-element');

        let window_width = window.innerWidth;
        let window_height = window.innerHeight;
        let window_top = document.body.scrollTop - 100;
        if (document.body.clientWidth <= 600) {
            window_top = document.body.scrollTop - 50;
        }
        let window_bottom = (window_top + window_height);

        for (const inter_element of interactive_elements) {
            let element_height = inter_element.clientHeight;
            let element_top = document.body.scrollTop + inter_element.getBoundingClientRect().top;
            let element_bottom = (element_top + element_height) - 20;

            if ((element_bottom >= window_top) && element_top <= window_bottom) {
                inter_element.classList.add('visible');
            }
        }
    }, 100);
});

let sections = document.querySelectorAll('.section');
let nav_links = document.querySelectorAll('.navigation__link');


function isScrolledIntoView(el){
    let rect = el.getBoundingClientRect();
    let elemTop = rect.top;
    let elemBottom = rect.bottom;
    let isVisible = (elemTop <= 300) && (elemBottom >= 400);
    if(isVisible){
        nav_links.forEach(link => {
            let href = link.getAttribute('href').substr(1);
            let id = el.getAttribute('id');
            link.classList.remove('navigation__link--active');
            if(href == id){
                link.classList.add('navigation__link--active')
            }
        })
    }
};

window.addEventListener('scroll', () => {
    let interactive_elements = document.querySelectorAll('.interactive-element');

    sections.forEach(section => {
        isScrolledIntoView(section);
    });


    let window_width = window.innerWidth;
    let window_height = window.innerHeight;
    let window_top = document.body.scrollTop - 100;
    if (document.body.clientWidth <= 600) {
        window_top = document.body.scrollTop - 50;
    }
    let window_bottom = (window_top + window_height);

    for (const inter_element of interactive_elements) {
        let element_height = inter_element.clientHeight;
        let element_top = document.body.scrollTop + inter_element.getBoundingClientRect().top;
        let element_bottom = (element_top + element_height) - 20;

        if ((element_bottom >= window_top) && element_top <= window_bottom) {
            inter_element.classList.add('visible');
        }
    }
});


let navigation_elements = document.querySelectorAll('.mobile-navigation .mobile-navigation__link');

navigation_elements.forEach(element => {
    element.addEventListener('click', function(e){
        let menu = document.querySelector('#mobile-menu');
        menu.classList.toggle("toggled");
    })
})

let menu_tabs = document.querySelectorAll('.js-menu-tabs');
if(menu_tabs !== null){
    menu_tabs.forEach(item => {
       new MenuTabs(item);
    });
}




