import Glide from "@glidejs/glide";

class MenuTabs {
    constructor(selector) {
        this.selector = selector;
        this.tabs = this.selector.querySelector('[data-element="tabs"]');
        this.container = this.selector.querySelector('[data-element="container"]');

        this.tabs.querySelectorAll('a').forEach(item => {
            item.addEventListener('click', (e) => {
                let currentTarget = e.currentTarget;
                let target = currentTarget.getAttribute('data-tab');
                this.tabs.querySelectorAll('a').forEach(href=> {
                   href.classList.remove('menu__restaurants-logo--shown');
                });
                if(!currentTarget.classList.contains('menu__restaurants-logo--shown')){
                    currentTarget.classList.add('menu__restaurants-logo--shown');
                }

                this.container.querySelectorAll('[data-target]').forEach(element => {
                    let elementAttribute = element.getAttribute('data-target');
                    if(element.classList.contains('slider--shown')){
                        element.classList.remove('slider--shown');
                    }
                    if(target === elementAttribute){
                        let prev_button = element.querySelector('[data-element="button-prev"]');
                        let next_button = element.querySelector('[data-element="button-next"]');
                        element.classList.add('slider--shown');
                        let run = element.getAttribute('data-run');
                        if(run !== "1") {
                            let glide = new Glide(element, {
                                perView: 1,
                                gap: '0',
                                type: 'carousel',
                            }).mount();

                            prev_button.addEventListener('click', (e) => {
                                e.preventDefault();

                                glide.go('<');
                            });
                            next_button.addEventListener('click', (e) => {
                                e.preventDefault();

                                glide.go('>');
                            });
                            element.setAttribute('data-run', 1);
                        }
                    }
                })
            })
        })
    }
}

export default MenuTabs;